import { combineReducers } from 'redux';
import plan from './planSlice';
import propertyReducer from './propertyReducer';
import user from './userSlice';

const rootReducer = combineReducers({
  user,
  plan,
  property: propertyReducer,
});

export default rootReducer;
