import React, { useState, useRef } from 'react';
import searchIc from './icons/searchIc.svg';
import backArrowIc from './icons/backArrow.svg';
import './index.css';
import { api } from '../../utils/api';
import { Spinner } from 'reactstrap';

export function SearchAddress({ onChange, ...props }) {
  const [searchText, setSearchText] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [moreAddressSelected, setMoreAddressSelected] = useState();
  const [IsLoadingMore, setIsLoadingMore] = useState(false);
  const [selected, setSelected] = useState();
  const searchTimeout = useRef();

  const _changeAddress = ({ target }) => {
    const value = target?.value ?? '';
    setSearchText(value);
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
      searchTimeout.current = null;
    }
    searchTimeout.current = setTimeout(async () => {
      await getAddressList(value);
      searchTimeout.current = null;
    }, 300);
  };

  const getAddressList = async (match, filter) => {
    const preSelectedAddress = selected?.locationsummary
      ? [selected?.summaryline, selected?.locationsummary].join(', ')
      : selected?.summaryline;
    if (selected?.id && match === preSelectedAddress) return;
    if (!match) {
      setAddressList([]);
      setSelected();
      return;
    }
    const params = { match };
    if (filter) params.filter = filter;
    const res = await api.GET('api/address/lookup', params);
    if (res?.status === 200) {
      setAddressList(res?.data ?? []);
    }
  };

  const _itemClicked = async (item) => {
    if (item?.type === 'ADD') {
      setSelected(item);
      setMoreAddressSelected();
      setSearchText(
        item?.locationsummary
          ? [item?.summaryline, item?.locationsummary].join(', ')
          : item?.summaryline,
      );
      setAddressList([]);
      if (onChange) onChange(item);
    } else {
      setMoreAddressSelected(item);
      setIsLoadingMore(true);
      await getAddressList(searchText, item?.id);
      setIsLoadingMore(false);
    }
  };

  const renderItem = (item, index) => {
    const addressText = item?.locationsummary
      ? [item?.summaryline, item?.locationsummary].join(', ')
      : item?.summaryline;
    return (
      <div
        key={String(index)}
        className="planna-address-suggestion-item"
        role="button"
        onClick={() => _itemClicked(item)}
      >
        <p>{addressText}</p>
        {item?.type !== 'ADD' ? (
          <p style={{ marginLeft: 10 }}>({item?.count}+ Addresses)</p>
        ) : null}
      </div>
    );
  };

  const _goBackSearch = async () => {
    setIsLoadingMore(true);
    await getAddressList(searchText);
    setIsLoadingMore(false);
    setMoreAddressSelected();
  };

  const renderListHeader = () => {
    if (!moreAddressSelected) return;
    const addressText = moreAddressSelected?.locationsummary
      ? [
          moreAddressSelected?.summaryline,
          moreAddressSelected?.locationsummary,
        ].join(', ')
      : moreAddressSelected?.summaryline;
    return (
      <div
        className="planna-address-suggestion-item"
        role="button"
        onClick={_goBackSearch}
      >
        <img src={backArrowIc} />
        <p className="mx-2">{addressText}</p>
        {IsLoadingMore ? (
          <div>
            <Spinner size="sm" color="primary" />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <div style={{ position: 'relative' }}>
          <input
            className="planna-register-input"
            placeholder="Start typing your address"
            name="address"
            value={searchText}
            onChange={_changeAddress}
            style={{ paddingRight: 41 }}
            {...props}
          />
          <button type="button" className="planna-register-input-right-button">
            <img src={searchIc} />
          </button>
        </div>
        {addressList?.length ? (
          <div className="planna-address-suggestion-container">
            {renderListHeader()}
            {addressList?.map(renderItem)}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}
