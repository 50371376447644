import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { USER_SESSION_EXPIRED } from '../actionTypes';
import { loadPropertyData } from '../actions/propertyActions';
import { actionFetchUserProfile, setRegisterData } from './userSlice';
import { PROPERTY_TYPES } from '../../constants';

export const actionAddressDetailsById = async (addressId) => {
  const { status, data } = await api.GET('address/lookup/' + addressId);
  if (status === 200) return data;
  console.log('actionAddressDetailsById[ERROR]', data);
  return;
};

export const actionGetAddressMeta = async (params) => {
  const { status, data } = await api.POST('od/external/tags', params);
  if (status === 200) return data;
  console.log('actionGetAddressMeta[ERROR]', data);
  return;
};

export const actionFetchScanPlan = createAsyncThunk(
  'plan/scanPlan/fetch',
  async ({ addressLookUpId, addressData, tags }, { dispatch }) => {
    try {
      if (!addressData) {
        addressData = await actionAddressDetailsById(addressLookUpId);
      }
      const { status, data } = await api.POST('property/details', addressData);
      if (status === 200) {
        let propertyData = data || {};
        let home_type = PROPERTY_TYPES[0];
        if (
          propertyData?.propertyType >= 1 &&
          propertyData?.propertyType <= PROPERTY_TYPES.length
        ) {
          home_type = PROPERTY_TYPES[propertyData?.propertyType - 1];
        }
        if (!tags) {
          console.log('Plan fetching details!');
          const meta = await actionGetAddressMeta({
            address: addressData?.summaryline,
          });
          console.log('Plan details Fetched!');
          tags = meta?.detections || [];
        }
        let etags = tags?.map((item) => ({
          id: item?.label,
          title: item?.label,
          parent: 'onboarding',
          confidence: item?.confidence,
        }));

        const cpy_property = {
          ...addressData,
          ...propertyData,
          residency:
            propertyData?.tenure == null
              ? null
              : propertyData?.tenure == 1
              ? 'Renter'
              : 'Homeowner',
          home: home_type,
          additionalAreas: [],
          livingWith: [],
          etags,
        };
        dispatch(setScanPlan(cpy_property));
        return cpy_property;
      }
      return;
    } catch (error) {
      console.log('actionFetchScanPlan[ERROR]', error);
    }
  },
);

export const actionSaveCurrentPlan = createAsyncThunk(
  'plan/save',
  async ({ addressLookUpId, addressDetails }, { dispatch, getState }) => {
    try {
      const { scanPlan } = getState().plan;
      let propertyRequest = scanPlan;
      if (!propertyRequest) {
        const scanPlanRes = await dispatch(
          actionFetchScanPlan({ addressLookUpId, addressData: addressDetails }),
        );
        if (scanPlanRes?.payload) {
          propertyRequest = scanPlanRes.payload;
        } else {
          console.log('actionSaveCurrentPlan[ERROR]', scanPlanRes);
          return;
        }
      }
      const request = {
        addressline1: propertyRequest.address,
        postalcode: propertyRequest.postcode,
        etags,
        lat: propertyRequest.lat,
        lng: propertyRequest.lng,
        buildingnumber: propertyRequest.buildingnumber,
        epcid: propertyRequest.epcid,
        crime: propertyRequest.crime,
        residency: propertyRequest.residency == 'Homeowner' ? 1 : 0,
      };
      const saveRes = await api.POST('properties/update', request);
      if (saveRes?.status === 200 || saveRes?.status === 201) {
        console.log('User plan has been created!');
        dispatch(loadPropertyData(saveRes?.data?.id));
        dispatch(actionFetchUserProfile());
        dispatch(setRegisterData({}));
        return true;
      } else {
        console.log('properties/update[ERROR]', saveRes?.status, saveRes?.data);
        return false;
      }
    } catch (error) {
      console.log('actionSaveCurrentPlan[ERROR]', error);
      return false;
    }
  },
);

const initialState = {
  scanPlan: null,
  magicAddressData: null,
  magicAddressMeta: null,
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setScanPlan: (state, action) => {
      state.scanPlan = action.payload;
    },
    setMagicAddressData: (state, action) => {
      state.magicAddressData = action.payload;
    },
    setMagicAddressMeta: (state, action) => {
      state.magicAddressMeta = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(USER_SESSION_EXPIRED, (state) => {
      state = Object.assign(state, initialState);
    });
  },
});

export const { setScanPlan, setMagicAddressData, setMagicAddressMeta } =
  planSlice.actions;

export default planSlice.reducer;
