import { Auth, Hub } from 'aws-amplify';
import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import './Apps.scss';
import './assets/css/colors/default.css';
import './assets/css/materialdesignicons.min.css';
import CookieBar from './components/Common/CookieBar';
import Layout from './components/Layout/';
import GuestLayout from './components/Layout/GuestLayout';
import {
  actionAccountSessionValidate,
  actionSignOut,
} from './redux/reducers/userSlice';
import routes from './routes';
import { api } from './utils/api';
import { GlobalDebug } from './utils/remove-console';

function withLayout(
  isGuest,
  WrappedComponent,
  hasDarkTopBar,
  hideHeader,
  fullWidth,
  isSticky,
) {
  return class App extends React.Component {
    render() {
      if (isGuest) {
        return (
          <GuestLayout
            hasDarkTopBar={hasDarkTopBar}
            hideHeader={hideHeader}
            fullWidth={fullWidth}
          >
            <WrappedComponent></WrappedComponent>
          </GuestLayout>
        );
      }
      return (
        <Layout
          hasDarkTopBar={hasDarkTopBar}
          hideHeader={hideHeader}
          isSticky={isSticky}
        >
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'STAGING') &&
      GlobalDebug(false);
  }, []);

  const handleAuth = async (data) => {
    const { payload } = data;
    if (payload?.event === 'signOut' || payload?.event === 'oAuthSignOut') {
      dispatch(actionSignOut());
      return;
    }
    if (payload?.event === 'signIn' || payload?.event === 'signUp') {
      const providerName = payload?.data?.signInUserSession?.idToken?.payload
        ?.identities
        ? payload?.data?.signInUserSession?.idToken?.payload?.identities[0]
            ?.providerName
        : 'email';

      const jwtToken = payload?.data?.signInUserSession?.accessToken?.jwtToken;
      if (jwtToken) {
        userSigned(jwtToken, providerName);
      }
    }
  };

  useEffect(() => {
    Hub.listen('auth', handleAuth);
    checkAuthState();
    return () => Hub.remove('auth', handleAuth);
  }, []);

  async function checkAuthState() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      const providerName = user?.signInUserSession?.idToken?.payload?.identities
        ? user?.signInUserSession?.idToken?.payload?.identities[0]?.providerName
        : 'email';
      const token = user?.signInUserSession?.accessToken?.jwtToken;
      userSigned(token, providerName);
    } catch (err) {
      dispatch(actionSignOut());
    }
  }

  const userSigned = async (token, providerName) => {
    if (!token) return;
    console.log('Account sign in using ' + providerName);
    api.defaultHeader({ Authorization: 'Bearer ' + token });
    await dispatch(actionAccountSessionValidate({ token, providerName }));
  };

  return (
    <React.Fragment>
      <Suspense fallback={Loader()}>
        <Switch>
          {routes.map((route, idx) =>
            route.isWithoutLayout ? (
              <Route
                path={route.path}
                exact={route.exact}
                component={route.component}
                key={idx}
              />
            ) : (
              <Route
                path={route.path}
                exact
                component={withLayout(
                  route.isGuest,
                  route.component,
                  route.isTopbarDark,
                  route.hideHeader,
                  route.fullWidth,
                  route.isSticky,
                )}
                key={idx}
              />
            ),
          )}
        </Switch>
        <CookieBar />
      </Suspense>
    </React.Fragment>
  );
};

export default withRouter(App);
