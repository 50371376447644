import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  actionCheckEmail,
  actionCheckUsername,
  actionEmailSignUp,
  setRegisterData,
} from '../../redux/reducers/userSlice';
import appleIc from './icons/appleIc.svg';
import eyeIc from './icons/eye.svg';
import eyeDarkIc from './icons/eyeDark.svg';
import facebookIc from './icons/facebookIc.svg';
import googleIc from './icons/googleIc.svg';
import './index.css';
import { SearchAddress } from './searchAddress';
import { actionFetchScanPlan } from '../../redux/reducers/planSlice';

export function RegisterForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { registerData } = useSelector((state) => state.user);
  const [provider, setProvider] = useState('email');

  const [state, setState] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    address: '',
    referralCode: '',
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');

  const disabled =
    provider === 'email'
      ? !state.username ||
        !state.address ||
        !state.email ||
        !state.password ||
        !state.confirmPassword ||
        state.password !== state.confirmPassword
      : !state.username || !state.address;

  const _changeInput = ({ target }) => {
    setState((pre) => {
      pre[target.name] = target.value;
      return { ...pre };
    });
  };

  const _changeAddress = (addressItem) => {
    setState((pre) => {
      pre.address = addressItem;
      return { ...pre };
    });
  };

  const _changeProvider = (value) => {
    setProvider((pre) => {
      if (pre === value) return 'email';
      return value;
    });
  };

  const _submit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const usernameStatus = await actionCheckUsername(
        state.username?.trim()?.toLowerCase(),
      );
      if (usernameStatus === true) {
        setIsLoading(false);
        return setUsernameErrorMsg('Username already in use.');
      } else setUsernameErrorMsg('');
      dispatch(actionFetchScanPlan({ addressLookUpId: state.address?.id }));
      if (provider === 'email') {
        const emailStatus = await actionCheckEmail(state.email?.toLowerCase());
        if (emailStatus === true) {
          setIsLoading(false);
          return setEmailErrorMsg('Email already in use.');
        } else setEmailErrorMsg('');
        const response = await dispatch(
          actionEmailSignUp({
            username: state.username?.trim()?.toLowerCase(),
            email: state.email?.toLowerCase(),
            password: state.password,
          }),
        );
        setIsLoading(false);
        if (response?.payload?.status) {
          dispatch(
            setRegisterData({
              ...registerData,
              username: state.username?.trim()?.toLowerCase(),
              email: state.email?.toLowerCase(),
              password: state.password,
              registrationInviteCode: state?.referralCode ?? '',
              address: state.address,
            }),
          );
          if (!response?.payload?.userConfirmed) {
            history.push(
              `/verification/${state.username?.trim()?.toLowerCase()}`,
            );
          }
        }
        return;
      } else {
        dispatch(
          setRegisterData({
            ...registerData,
            username: state.username?.trim()?.toLowerCase(),
            registrationInviteCode: state?.referralCode ?? '',
            address: state.address,
          }),
        );
        Auth.federatedSignIn({ provider });
      }
    } catch (error) {
      console.log('register form submit[ERROR] ', error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={_submit} className="planna-register-form">
        <h3 className="planna-register-form-title">Create your account</h3>
        <input
          className="planna-register-input"
          placeholder="Create a username"
          name="username"
          value={state.username}
          onChange={_changeInput}
        />
        {usernameErrorMsg && (
          <p className="planna-register-input-error-msg">{usernameErrorMsg}</p>
        )}

        <SearchAddress onChange={_changeAddress} />

        <input
          className="planna-register-input"
          placeholder="Enter your email address "
          name="email"
          onChange={_changeInput}
        />
        {emailErrorMsg && (
          <p className="planna-register-input-error-msg">{emailErrorMsg}</p>
        )}
        <div style={{ position: 'relative' }}>
          <input
            className="planna-register-input"
            placeholder="Create your password"
            name="password"
            type={passwordVisible ? 'text' : 'password'}
            onChange={_changeInput}
            style={{ paddingRight: 41 }}
          />
          <button
            type="button"
            onClick={() => setPasswordVisible(!passwordVisible)}
            className="planna-register-input-right-button"
          >
            <img src={passwordVisible ? eyeDarkIc : eyeIc} />
          </button>
        </div>
        <div style={{ position: 'relative' }}>
          <input
            className="planna-register-input"
            placeholder="Re-enter your password"
            name="confirmPassword"
            type={confirmPasswordVisible ? 'text' : 'password'}
            onChange={_changeInput}
            style={{ paddingRight: 41 }}
          />
          <button
            type="button"
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
            className="planna-register-input-right-button"
          >
            <img src={confirmPasswordVisible ? eyeDarkIc : eyeIc} />
          </button>
        </div>
        <p className="planna-register-option-title">Or Continue With</p>
        <div className="d-flex justify-content-around text-center mb-4">
          <div role="button" onClick={() => _changeProvider('Facebook')}>
            <div
              className={`planna-register-social-icon ${
                provider == 'Facebook' && 'planna-register-social-icon-active'
              }`}
            >
              <img src={facebookIc} />
            </div>
            <span className="planna-register-social-title">Facebook</span>
          </div>
          <div role="button" onClick={() => _changeProvider('Google')}>
            <div
              className={`planna-register-social-icon ${
                provider == 'Google' && 'planna-register-social-icon-active'
              }`}
            >
              <img src={googleIc} />
            </div>

            <span className="planna-register-social-title">Google</span>
          </div>
          <div role="button" onClick={() => _changeProvider('SignInWithApple')}>
            <div
              className={`planna-register-social-icon ${
                provider == 'SignInWithApple' &&
                'planna-register-social-icon-active'
              }`}
            >
              <img src={appleIc} />
            </div>

            <span className="planna-register-social-title">Apple</span>
          </div>
        </div>
        <p className="planna-register-referral-input-title">
          Have a Referral Code?
        </p>
        <input
          className="planna-register-input"
          name="referralCode"
          value={state.referralCode}
          onChange={_changeInput}
          style={{ border: 'solid 1px #EFEFEF', textAlign: 'center' }}
        />

        <p className="planna-register-policy-msg">
          By continuing, you’re agreeing to our
          <br />
          <a
            href="https://blog.planna.co/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Customer Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://blog.planna.co/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <button
          disabled={disabled}
          className={`planna-register-submit-button ${
            disabled ? 'disabled' : ''
          }`}
          type="submit"
        >
          {IsLoading ? 'Creating account...' : 'Create my Account'}
        </button>
      </form>
    </React.Fragment>
  );
}
