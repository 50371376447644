import apiRequestGuest from './apiRequestGuest';
import apiRequest from './apiRequest';

const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';
const PUT = 'PUT';
const PATCH = 'PATCH';

const PropertyService = {
  getInfo(postcode, address) {
    return apiRequestGuest({
      method: GET,
      url: `property/info?postcode=${postcode}&address=${address}`,
    });
  },
  getDetail(epcid) {
    return apiRequestGuest({
      method: GET,
      url: `property/detail?uprn=${epcid}`,
    });
  },
  getNoEpcDetail(postcode, address) {
    return apiRequestGuest({
      method: GET,
      url: `property/detailnoepc?address=${address}&postcode=${postcode}`,
    });
  },

  savePropertyData(data) {
    return apiRequest({
      method: POST,
      url: `properties/update`,
      data: JSON.stringify(data),
    });
  },
  getPropertyData(property_id) {
    return apiRequest({
      method: GET,
      url: `properties/${property_id}`,
    });
  },
};

export default PropertyService;
