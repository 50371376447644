import * as actions from '../actions/propertyActions';

const initialState = {
  address: null,
  buildType: null,
  builtDate: null,
  coEmission: null,
  crime: {},
  energyCostSaving: null,
  energyRating: null,
  epcid: null,
  floodRisk: null,
  floorArea: null,
  lat: null,
  lng: null,
  leaseType: null,
  numOfBathrooms: null,
  numOfBedrooms: null,
  numOfReception: null,
  propertyType: null,
  propertyTypeDescription: null,
  runningCosts: null,
  tenure: null,
  uprn: null,
  windowType: null,
  epc: null,
  home_transactions: null,
  // address details
  postcode: null,
  line_1: null,
  line_2: null,
  province_code: null,
  town: null,
  country_name: null,

  // home owner type
  residency: null,

  // home type
  home: null,

  // more details
  additionalAreas: [],
  livingWith: [],
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SAVE_PROPERTY_DATA: {
      return {
        ...state,
        ...action.propertyData,
      };
    }
    default:
      return { ...state };
  }
};

export default propertyReducer;
