import Config from './constants/config';

// const awsmobile = {
//     "aws_project_region": "us-east-2",
//     "aws_cognito_identity_pool_id": "us-east-2:79920b49-d296-4a2f-8a42-c98317571856",
//     "aws_cognito_region": "us-east-2",
//     "aws_user_pools_id": "us-east-2_dzxHebtv7",
//     "aws_user_pools_web_client_id": "4lt3al7g05c3git2cajbvij5va",
//     "oauth": {
//         "domain": "plannacd23eb31-cd23eb31-dev.auth.us-east-2.amazoncognito.com",
//         "scope": [
//             "phone",
//             "email",
//             "openid",
//             "profile",
//             "aws.cognito.signin.user.admin"
//         ],
//         "redirectSignIn": Config.REDIRECT_SIGNIN,
//         "redirectSignOut": Config.REDIRECT_SIGNOUT,
//         "responseType": "code"
//     },
//     "federationTarget": "COGNITO_USER_POOLS",
//     "aws_cognito_username_attributes": [],
//     "aws_cognito_social_providers": [
//         "GOOGLE"
//     ],
//     "aws_cognito_signup_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_mfa_configuration": "OFF",
//     "aws_cognito_mfa_types": [
//         "SMS"
//     ],
//     "aws_cognito_password_protection_settings": {
//         "passwordPolicyMinLength": 8,
//         "passwordPolicyCharacters": []
//     },
//     "aws_cognito_verification_mechanisms": [
//         "EMAIL"
//     ],
//     "aws_cognito_login_mechanisms": [
//         "EMAIL",
//         "PREFERRED_USERNAME"
//     ]
// };

const awsmobile = {
    "aws_project_region": "eu-west",
    "aws_cognito_identity_pool_id": "eu-west-2:d5d14d56-2dca-4dc3-9901-d70a1f85eb2c",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_SYfqgKlcY",
    "aws_user_pools_web_client_id": "4mgqv9gq8ijkuq0i1btvl67sot",
    "oauth": {
        "domain": "auth2.planna.co",
        "domain2": "planna.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": Config.REDIRECT_SIGNIN,
        "redirectSignOut": Config.REDIRECT_SIGNOUT,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE","APPLE","FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;