import React from 'react';
//Special
const PageCareers = React.lazy(() => {
  window.location.href = 'https://blog.planna.co/careers';
  return null;
});
const PageBlog = React.lazy(() => {
  window.location.href = 'https://blog.planna.co/';
  return null;
});

const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));

// Root Include
const Landing = React.lazy(() => import('./pages/Landing/index'));
const AboutUs = React.lazy(() => import('./pages/AboutUs/index'));
const PageContact = React.lazy(() => import('./pages/Contact/index'));
const PagePricing = React.lazy(() => import('./pages/Pricing/index'));
const FindAddress = React.lazy(() =>
  import('./pages/GuestPages/FindAddress/index'),
);
const NotFoundAddress = React.lazy(() =>
  import('./pages/GuestPages/NotFoundAddress/index'),
);
const Signup = React.lazy(() => import('./pages/GuestPages/Signup/index'));
const Verification = React.lazy(() =>
  import('./pages/GuestPages/Verification/index'),
);
const SignupSuccess = React.lazy(() =>
  import('./pages/GuestPages/SignupSuccess/index'),
);
const Rewards = React.lazy(() => import('./pages/Rewards/index'));
// Activity Include
const ActivityPage = React.lazy(() => import('./pages/Activity/index'));
const ShareApp = React.lazy(() => import('./pages/GuestPages/ShareApp'));
const AskPro = React.lazy(() => import('./pages/askPro/index'));
const BusinessPartner = React.lazy(() =>
  import('./pages/BusinessPartner/index'),
);
const BusinessContact = React.lazy(() => import('./pages/BusinessContact'));

const Vault = React.lazy(() => import('./pages/Vault'));
const AIScanner = React.lazy(() => import('./pages/AIScanner'));
const Anna = React.lazy(() => import('./pages/Anna'));

const signUp = React.lazy(() => import('./pages/signUp'));

const routes = [
  // routes without Layout
  {
    path: '/askapro',
    component: AskPro,
    isTopbarDark: true,
    isSticky: false,
    exact: true,
    isGuest: false,
  },
  {
    path: '/planna-for-business',
    component: BusinessPartner,
    isTopbarDark: false,
    isSticky: true,
    exact: true,
    isGuest: false,
  },
  {
    path: '/business-contact',
    component: BusinessContact,
    isTopbarDark: false,
    isSticky: true,
    exact: true,
    isGuest: false,
  },
  {
    path: '/about',
    component: AboutUs,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/careers',
    component: PageCareers,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/contact',
    component: PageContact,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/pricing',
    component: PagePricing,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  { path: '/blog', component: PageBlog, isGuest: false },
  {
    path: '/app/c/p/:card_id',
    component: ActivityPage,
    hideHeader: false,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/address',
    component: FindAddress,
    isTopbarDark: true,
    isSticky: false,
    isGuest: true,
  },
  {
    path: '/rewards',
    component: Rewards,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/no-address',
    component: NotFoundAddress,
    isTopbarDark: true,
    isSticky: true,
    isGuest: true,
  },
  {
    path: '/signup-success',
    component: SignupSuccess,
    isTopbarDark: true,
    isSticky: false,
    isGuest: true,
  },
  {
    path: '/signup',
    component: Signup,
    isTopbarDark: true,
    isSticky: false,
    isGuest: true,
  },
  {
    path: '/verification/:username',
    component: Verification,
    isTopbarDark: true,
    isSticky: false,
    isGuest: true,
  },
  {
    path: '/share',
    component: ShareApp,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/ref/apps',
    component: ShareApp,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/invite',
    component: ShareApp,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },

  //Index root
  {
    path: '/',
    component: Landing,
    isTopbarDark: true,
    isSticky: false,
    exact: true,
    isGuest: false,
  },
  {
    path: '/r/',
    component: Landing,
    isTopbarDark: true,
    isSticky: false,
    exact: true,
    isGuest: false,
  },
  {
    path: '/annaai',
    component: Anna,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/vault',
    component: Vault,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/ai-scanner',
    component: AIScanner,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  {
    path: '/register-account',
    component: signUp,
    isTopbarDark: true,
    isSticky: false,
    isGuest: false,
  },
  { component: PageError, exact: false },
];

export default routes;
