import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import './assets/css/index.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga4';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';

Amplify.configure(awsExports);

ReactGA.initialize('G-0D8E8QZBLW');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);

serviceWorker.unregister();
