import { Auth } from 'aws-amplify';
import axios from 'axios';
import Config from '../constants/config';
// import store from '../redux/store';
import { getAppToken, setAppToken } from '../utils/storage';

const checkApiResponse = async (token) => {
  return axios({
    method: 'get',
    url: `${Config.API_BASE_URL}/tags/c/areas`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

export default async (request) => {
  request.method = request.method || 'get';
  request.url = `${Config.API_BASE_URL}/${request.url}`;

  console.log('== REQUEST API : ', request.url);

  if (request.data && request.method === 'get') {
    // If data is set the get request won't be made
    request.data = null;
  }

  request.headers = request.headers || {
    'Content-Type': 'application/json',
  };

  const token = await getAppToken();
  if (token) {
    request.headers['Authorization'] = 'Bearer ' + token;
  }

  try {
    if (token) {
      await checkApiResponse(token);
    }
    return axios(request);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      console.log('== REFRESH METHOD STARTED ==');

      const currentSession = await Auth.currentSession();
      const newJwtToken = currentSession.getAccessToken().getJwtToken();

      setAppToken(newJwtToken);
      // REPLACE TOKEN WITH THE NEW ONE
      request.headers['Authorization'] = 'Bearer ' + newJwtToken;
    }
    return axios(request);
  }
};
