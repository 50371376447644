import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { api } from '../../utils/api';
import './index.css';

export default function Contact() {
  return (
    <React.Fragment>
      <div className="planna-chat-boot-widget-container-contact">
        <ContactForm />
      </div>
    </React.Fragment>
  );
}

function ContactForm() {
  const [state, setState] = useState({
    full_name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (status === 'success') {
      setState({
        full_name: '',
        email: '',
        message: '',
      });
    }
  }, [status]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
    const res = await api.POST('api/contact/subscribe', {
      fullname: state.full_name,
      email: state.email,
      comment: state.message,
      list: 'help',
      ref: location.href,
    });
    setStatus('success');
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  if (status === 'success') {
    return (
      <Alert color="primary" isOpen={true} style={{ marginTop: 15 }}>
        We've received your email and will get back to you within 1-2 business
        days.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: 'inherit',
        display: 'grid',
        justifyContent: 'space-between',
      }}
    >
      <h4 className="text-center">Send us a message</h4>
      <div>
        <input
          className="planna-chat-boot-widget-container-contact-input"
          placeholder="Your Full Name*"
          name="full_name"
          value={state.full_name}
          onChange={handleChange}
        />
        <input
          className="planna-chat-boot-widget-container-contact-input"
          placeholder="Your Email Address*"
          name="email"
          value={state.email}
          onChange={handleChange}
        />
        <textarea
          className="planna-chat-boot-widget-container-contact-input"
          placeholder="How can we help you?*"
          name="message"
          value={state.message}
          onChange={handleChange}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
}
