export const MailchimpSubscribeUrl = `https://planna.us20.list-manage.com/subscribe/post?u=9e4d3f2438b3270d277a7686c&amp;id=c43dfc61e0`;
export const MailchimpBusinessContact = `https://planna.us20.list-manage.com/subscribe/post?u=9e4d3f2438b3270d277a7686c&amp;id=134f0933d9&amp;f_id=003208eaf0`;
export const KEY_COST = 'cost';
export const KEY_SAFETY = 'safety';
export const KEY_VALUE = 'value';
export const KEY_SUSTAINABILITY = 'sustainability';

export const PLANNA_TERMS_URL = 'https://blog.planna.co/terms-of-use/';
export const PLANNA_PRIVACY_URL = 'https://blog.planna.co/privacy/';
export const PLANNA_Instagram_URL = 'https://www.instagram.com/planna.co/';
export const PLANNA_Twitter_URL = 'https://twitter.com/Planna_Co';
export const PLANNA_Facebook_URL = 'https://www.facebook.com/Planna.limited';
export const PLANNA_Linkedin_URL = 'https://www.linkedin.com/company/plannaai';
export const PLANNA_Threads_URL = 'https://www.threads.net/@planna.co';

export const MailchimpNewsletter = `https://planna.us20.list-manage.com/subscribe/post?u=9e4d3f2438b3270d277a7686c&amp;id=16f83da035&amp;f_id=000218eaf0`;
export const MailchimpReportNewsletter = `https://planna.us20.list-manage.com/subscribe/post?u=9e4d3f2438b3270d277a7686c&amp;id=6b0196fbb8&amp;f_id=000a18eaf0`;

export const PROPERTY_OWNER_TYPES = ['Homeowner', 'Renter'];
export const PROPERTY_TYPES = [
  'Terraced house',
  'End terraced house',
  'Mid terraced house',
  'Detached house',
  'Bungalow',
  'Flat',
  'Maisonette',
];
