import React, { Component, Suspense, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// Scroll up button
import ScrollUpButton from 'react-scroll-up-button';
//Import Icons
import FeatherIcon from 'feather-icons-react';

// Layout Components
const GuestTopbar = React.lazy(() => import('./GuestTopbar/index'));

const Footer = React.lazy(() => import('./Footer'));

const CustomDot = () => {
  return (
    <React.Fragment>
      <FeatherIcon icon='arrow-up' className='icons' />
    </React.Fragment>
  );
};

const Loader = () => {
  return (
    <div id='preloader'>
      <div id='status'>
        <div className='spinner'>
          <div className='double-bounce1'></div>
          <div className='double-bounce2'></div>
        </div>
      </div>
    </div>
  );
};

const GuestLayout = (props) => {
  // useEffect(() => {
  //   window.addEventListener('scroll', scrollNavigation, true);
  //
  //   return () => {
  //     window.removeEventListener('scroll', scrollNavigation, true);
  //   };
  // }, []);
  //
  // const scrollNavigation = () => {
  //   var doc = document.documentElement;
  //   var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   if (top > 80) {
  //     document.getElementById('topnav').classList.add('nav-sticky');
  //   } else {
  //     document.getElementById('topnav').classList.remove('nav-sticky');
  //   }
  // };

  return (
    <React.Fragment>
      <Suspense fallback={Loader()}>
        <GuestTopbar hideHeader={props.hideHeader} fullWidth={props.fullWidth}/>
        {props.children}
        <Footer />
        <ScrollUpButton
          ContainerClassName='classForContainer'
          style={{ height: 36, width: 36 }}
          TransitionClassName='classForTransition'
        >
          <CustomDot />
        </ScrollUpButton>
      </Suspense>
    </React.Fragment>
  );
};

export default withRouter(GuestLayout);
