
export const getCompletedActivities = (data) => {
  if (data?.cardlists?.length > 0 && data?.cardlists[0].listitems?.length > 0) {
    return data.cardlists[0].listitems.filter(item => item.status > 0);
  }
  return [];
}

export const getInProgressActivities = (data) => {
  if (data?.cardlists?.length > 0 && data?.cardlists[0].listitems?.length > 0) {
    return data.cardlists[0].listitems.filter(item => item.status == null || item.status === 0);
  }
  return [];
}


export const getTotalActivities = (data) => {
  if (data?.cardlists?.length > 0 && data?.cardlists[0].listitems?.length > 0) {
    return data.cardlists[0].listitems.filter(item => item.status !== -1);
  }
  return [];
}

export const getTotalPoints = (data) => {
  return (data?.impactcost || 0) + (data?.impacthealthSafety || 0) + (data?.impactsustainability || 0) + (data?.impactvaluation || 0);
}

export const isEmpty = (str) => {
  if (str == null || str === '') {
    return true
  }
  return false
}


export function validateHTMLString(str) {
  let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  let isValid = regexForHTML.test(str ?? '');
  return isValid;
}
