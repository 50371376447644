import { setStorageKey, KEYS } from '../../utils/storage';
import PropertyService from '../../services/apiProperty';
import { PROPERTY_TYPES } from '../../constants';

export const SAVE_PROPERTY_DATA = 'SAVE_PROPERTY_DATA';

export const savePropertyData = propertyData => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let cpy_property = { ...getState().property, ...propertyData };
      await setStorageKey(KEYS.SAVED_PROPERTY, cpy_property);
      await dispatch({
        type: SAVE_PROPERTY_DATA,
        propertyData,
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};


export const loadPropertyData = (property_id) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    PropertyService.getPropertyData(property_id)
      .then(({ data }) => {
        console.log('load property data ', data);

        let home_type = PROPERTY_TYPES[0];
        let numOfBedrooms = 0;
        let numOfBathrooms = 0;
        let numOfReception = 0;
        let additionalAreas = [];
        let livingWith = [];

        if (data.etags) {
          let _index = data.etags.findIndex(t => t.id == 'bedrooms');
          if (_index != -1) {
            let bedroomTag = data.etags[_index];
            let bedroom_cnt = parseInt(bedroomTag.title);
            if (!isNaN(bedroom_cnt)) {
              numOfBedrooms = bedroom_cnt;
            }
          }
          _index = data.etags.findIndex(t => t.id == 'bathrooms');
          if (_index != -1) {
            let bathroomTag = data.etags[_index];
            let bathroom_cnt = parseInt(bathroomTag.title);
            if (!isNaN(bathroom_cnt)) {
              numOfBathrooms = bathroom_cnt;
            }
          }
          _index = data.etags.findIndex(t => t.id == 'receptions');
          if (_index != -1) {
            let receptionTag = data.etags[_index];
            let reception_cnt = parseInt(receptionTag.title);
            if (!isNaN(reception_cnt)) {
              numOfReception = reception_cnt;
            }
          }

          _index = data.etags.findIndex(t => t.parent == 'housetype');
          if (_index != -1) {
            if (data.etags[_index].id) {
              home_type = data.etags[_index].id;
            }
          }

          let tmpAreas = data.etags.filter(t => (
            t.parent == 'areas' &&
            t.id != 'bedrooms' &&
            t.id != 'bathrooms' &&
            t.id != 'receptions'
          ));
          additionalAreas = tmpAreas.map(t => ({ ...t, value: 1 }));

          let tmpLivings = data.etags.filter(t => t.parent == 'livewith');
          livingWith = tmpLivings.map(t => ({ ...t, value: 1 }));
        }

        let propertyData = {
          lat: data.lat,
          lng: data.lng,
          postcode: data.postalcode,
          epcid: data.epcid,
          address: data.addressline1,
          residency: data.residency == 1 ? 'Homeowner' : 'Renter',
          crime: data.crime,
          numOfBedrooms: numOfBedrooms,
          numOfBathrooms: numOfBathrooms,
          numOfReception: numOfReception,
          home: home_type,
          additionalAreas: additionalAreas,
          livingWith: livingWith,
        };

        let cpy_property = { ...getState().property, ...propertyData };
        setStorageKey(KEYS.SAVED_PROPERTY, cpy_property);
        dispatch({
          type: SAVE_PROPERTY_DATA,
          propertyData,
        });
        resolve(propertyData);
      })
      .catch(e => {
        console.log('load PropertyData err ', e);
        reject(e);
      });
  });
};