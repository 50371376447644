import React, { useEffect, useState } from 'react';
import { cookies } from '../../utils/storage'

const CookieBar = () => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        let allow = cookies.get('allow-cookies');
        setVisible(allow != 'true')
    }, [])

    const onAllow = () => {
        let d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 year expiration
        cookies.set('allow-cookies', 'true', { path: '/', expires: d });
        setVisible(false)
    }

    if (!visible) {
        return null;
    }
    return (
        <div className="cookie-bar">
            <div className="cookie-bar-content">
                <div className="cookie-message">We use cookies <span className="emoji" role="img" aria-label="Cookie">🍪</span> to provide the best experience.
                    <a href='http://blog.planna.co/cookie-policy/'> Find out more about cookies.
                    </a></div>
                <button type="button" className="cookie-button" onClick={onAllow}>I understand</button>
            </div>
        </div>
    )
}

function arePropsEqual(prevProps, nextProps) {
    return true;
}
export default React.memo(CookieBar, arePropsEqual);

