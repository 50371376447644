import React, { useMemo } from 'react';
import messageIc from './messageIc.svg';
import avatarIc from './avatarIc.svg';
import ActivityCard from './ActivityCard';
import ProServerCard from './ProServerCard';
import { validateHTMLString } from '../../../utils/helper';
import parse from 'html-react-parser';

export default function ConversationItem({ item }) {
  const IsRight = item?.itemType === 'question';
  const text = useMemo(() => {
    if (item?.itemType === 'question') return item?.text;
    let answerTxt = item?.data?.answer ?? '';

    return answerTxt;
  }, [item]);
  const IsHtml = useMemo(() => validateHTMLString(text), [text]);

  const activityData = useMemo(() => item?.data?.card, [item?.data?.card]);
  const proServiceData = useMemo(
    () => [...(item?.data?.proServices ?? []), ...(item?.data?.pros ?? [])],
    [item?.data?.proServices, item?.data?.pros],
  );

  return (
    <React.Fragment>
      <div
        className={`conversation-item ${
          IsRight ? 'question-item' : 'answer-item'
        }`}
      >
        <img src={IsRight ? avatarIc : messageIc} className="message-icon" />
        <div>
          {IsHtml ? parse(text) : <p>{text}</p>}
          {activityData ? <ActivityCard data={activityData} /> : null}
          {proServiceData?.length ? (
            <ProServerCard data={proServiceData} />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}
