import React, { useCallback } from 'react';
import './index.css';
import verifyIc from './verifyIc.svg';
import avatarIc from './avatarIc.svg';
import Config from '../../../constants/config';

export default function ProServerCard({ data }) {
  const renderItem = useCallback((item, index) => {
    const pro = item?.pro;
    const title = pro?.user?.firstName;
    const imageUrl = pro?.user?.imageUrl;
    const businessTitle = pro?.business?.title;
    const tags = pro?.etags;

    const available = pro?.status == 1;
    const rates = item?.rates;
    const hourly_rate = rates[0];

    const _handleView = useCallback(() => {
      const url = Config.BASE_URL + 'r/ANNAWEB01';
      window.open(url, '__blank');
    }, []);

    return (
      <div key={String(index)} className="planna-chat-boot-widget-service-card">
        <div className="service-card-header">
          <div style={{ position: 'relative' }}>
            <img
              src={imageUrl ?? avatarIc}
              alt={title}
              className="service-card-profile-picture"
            />
            <img
              src={verifyIc}
              className="service-card-profile-picture-verify"
            />
          </div>
          <div style={{ marginLeft: 10, width: 'calc(100% - 40px)' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span className="service-card-title">{title}</span>
              <span
                className="service-card-container-span-title"
                style={{ marginLeft: 10, color: '#3AABB0' }}
              >
                <span style={{ fontSize: 14 }}>
                  {hourly_rate?.cost == 0 ? 'Free' : `£${hourly_rate?.cost}`}
                </span>
                /{hourly_rate?.durantion ?? 15} mins
              </span>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 10,
                  backgroundColor: available ? '#4CD7AD' : '#F1364E',
                  marginRight: 5,
                }}
              />
              <span className="service-card-container-span-title">
                {available ? 'Available' : 'Away'}
              </span>
            </div>
          </div>
        </div>
        <div className="service-card-container">
          {tags?.map((tag, i) => (
            <span key={tag?.id} className="service-card-container-span-tag">
              {tag?.title}
            </span>
          ))}
        </div>
        <button
          className="service-card-container-view-button"
          onClick={_handleView}
        >
          View Profile
        </button>
      </div>
    );
  }, []);

  return <React.Fragment>{data?.map(renderItem)}</React.Fragment>;
}
