import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

import SignUpModal from '../../pages/signUp/signUpModal';
import Widget from '../widget';

const Topbar = React.lazy(() => import('./Topbar/index'));
const Footer = React.lazy(() => import('./Footer'));

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsSticky: false,
    };
  }

  componentDidMount() {
    document.body.classList = '';
  }

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          <Topbar
            hasDarkTopBar={this.props.hasDarkTopBar}
            hideHeader={this.props.hideHeader}
            isSticky={this.props.isSticky}
          />
          {this.props.children}
          <Footer />
          <Widget />
          <SignUpModal />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
