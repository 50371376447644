import React, { useCallback, useMemo } from 'react';
import { getTotalActivities } from '../../../utils/helper';
import clockIc from './clockIc.svg';
import './index.css';
import levelIc from './levelIc.svg';

export default function ActivityCard({ data }) {
  if (!data) return null;
  const title = data?.title;
  const complexity = data?.complexity ?? '';

  const numTotal = useMemo(() => {
    const activities = getTotalActivities(data);
    return activities.length;
  }, [data]);

  const timeToComplete = useMemo(() => {
    let timeToComplete = data?.timeToComplete?.replace('hour', 'hr');
    timeToComplete = timeToComplete?.replace('mins.', 'mins');
    return timeToComplete;
  }, [data?.timeToComplete]);

  const _handleView = useCallback(() => {
    let cardID = data?.id;
    if (data?.parent != null) cardID = data.parent || data?.id;
    if (cardID == null) return;
    const url = `https://planna.co/app/c/p/${cardID}`;
    window.open(url, '__blank');
  }, [data?.id, data.parent]);

  return (
    <React.Fragment>
      <div className="planna-chat-boot-widget-activity-card">
        <div className="activity-card-header">
          <span className="activity-card-title">{title}</span>
          <div className="activity-card-task-circle">
            <span className="activity-card-task-circle-count">{numTotal}</span>
            <span className="activity-card-task-circle-label">
              {numTotal == 1 ? 'Task' : 'Tasks'}
            </span>
          </div>
        </div>
        <div className="activity-card-container">
          <div>
            <img src={levelIc} />
            <span className="activity-card-container-span-title">
              {complexity}
            </span>
          </div>
          <div>
            <img src={clockIc} />
            <span className="activity-card-container-span-title">
              {timeToComplete}
            </span>
          </div>
        </div>
        <button
          className="activity-card-container-view-button"
          onClick={_handleView}
        >
          View
        </button>
      </div>
    </React.Fragment>
  );
}
