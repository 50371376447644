export const SUPPORT_LIST_QUESTIONS = [
  { title: 'How do I', description: 'Add more activities?' },
  { title: 'How do you', description: 'use my data?' },
  { title: 'When do you', description: 'add rewards?' },
  { title: 'Are you getting paid', description: 'to refer products to me?' },
  { title: 'How do I add', description: 'a document to my vault?' },
  { title: 'Why have my', description: 'points gone?' },
  { title: 'How does the', description: 'points system work?' },
  { title: 'How do you know', description: 'about my property?' },
];
