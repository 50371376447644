import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { USER_SESSION_EXPIRED } from '../actionTypes';
import { api } from '../../utils/api';
import { Auth } from 'aws-amplify';
import { showAlert } from '../../utils/alerts';
import { actionSaveCurrentPlan } from './planSlice';

export const actionCheckUsername = async (username) => {
  const { status, data } = await api.GET('api/admin/users/check', {
    login: username,
  });
  if (status === 200) return data;
  return false;
};

export const actionCheckEmail = async (email) => {
  const { status, data } = await api.GET('api/admin/users/checkemail', {
    email,
  });
  if (status === 200) return data;
  return false;
};

export const actionAccountSessionValidate = createAsyncThunk(
  'user/account/session',
  async ({ token, providerName }, { dispatch }) => {
    const res = await api.GET('account');
    if (res?.status === 200 || res?.status === 201) {
      await dispatch(actionHandleSignIn(token, providerName));
      return true;
    }
    console.log('actionAccountSessionValidate[ERROR]', res?.status);
    dispatch(actionSignOut());
    return false;
  },
);

export const actionHandleSignIn = (token, providerName) => {
  return async (dispatch, getState) => {
    try {
      let { profile, registerData } = getState().user;
      dispatch(setUserAuthToken(token));
      if (registerData?.registrationInviteCode)
        await api.POST('account/extra', {
          key: 'inviter_referral_code',
          value: registerData?.registrationInviteCode,
        });
      if (!profile?.extras?.property_id) {
        const response = await dispatch(actionFetchUserProfile());
        if (!response?.payload) {
          console.log(
            'userSigned[ERROR] API Failed to retrieve profile.',
            response,
          );
          return dispatch(actionSignOut());
        }
        profile = response?.payload;
      }
      let propertyId = profile?.extras?.property_id;
      if (propertyId) {
        return true;
      } else {
        if (registerData?.address?.id) {
          if (providerName === 'email') {
            const response = await dispatch(
              actionSaveCurrentPlan({
                addressLookUpId: registerData?.address?.id,
              }),
            );
            console.log('Plan updated!', response);
          } else {
            if (registerData?.username)
              await dispatch(
                actionUpdateProfile({
                  username: registerData?.username,
                  firstName: '',
                  lastName: '',
                }),
              );
            const response = await dispatch(
              actionSaveCurrentPlan({
                addressLookUpId: registerData?.address?.id,
              }),
            );
            console.log('Plan updated!', response);
          }
          return true;
        } else {
          console.log('No selected plan found.');
        }
      }
    } catch (error) {
      toggleLoading(false);
      console.log('actionHandleSignIn[ERROR]', error);
      dispatch(actionSignOut());
    }
  };
};

export const actionUpdateProfile = (formData) => async (dispatch) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    let preferred_username = formData.username;
    if (!preferred_username)
      preferred_username =
        user?.signInUserSession?.idToken?.payload?.preferred_username ||
        user?.username;
    await Auth.updateUserAttributes(user, {
      name: formData?.firstName
        ? `${formData?.firstName} ${formData?.lastName}`
        : '',
      given_name: formData?.firstName ?? '',
      family_name: formData?.lastName ?? '',
      preferred_username,
    });
    await api.POST('account/update', {
      firstName: formData?.firstName ?? '',
      lastName: formData?.lastName ?? '',
      preferred_username,
    });
    if (formData?.inviter_referral_code) {
      api.POST('account/extra', {
        key: 'inviter_referral_code',
        value: formData?.inviter_referral_code,
      });
    }
    dispatch(
      updateProfileData({
        firstName: formData?.firstName ?? '',
        lastName: formData?.lastName ?? '',
        preferred_username,
      }),
    );
    return { status: true };
  } catch (error) {
    alerts.error(null, 'Something went wrong!');
    console.log('actionUpdateProfile[ERROR]', error);
    return { status: false };
  }
};

export const actionSignOut = createAsyncThunk(
  'user/signOut',
  async (_, { dispatch }) => {
    dispatch({ type: USER_SESSION_EXPIRED });
    api.defaultHeader({ Authorization: null });
    // Auth.signOut();
  },
);

export const actionEmailSignUp = createAsyncThunk(
  'user/email/signUp',
  async (formData, { dispatch }) => {
    try {
      const authRes = await Auth.signUp({
        username: formData.username,
        password: formData.password,
        attributes: {
          email: formData.email,
          // name: `${formData.firstName} ${formData.lastName}`,
          // given_name: formData.firstName,
          // family_name: formData.lastName,
        },
        autoSignIn: {
          enabled: true,
        },
      });
      console.log('Account registered successfully.', authRes);
      if (authRes.userConfirmed) {
        dispatch(setRegisterSuccess(true));
      }
      return { status: true, userConfirmed: authRes.userConfirmed };
    } catch (error) {
      let errorMsg = error?.message || 'Something went wrong!';
      if (error?.code == 'InvalidPasswordException') {
        errorMsg =
          'Passwords should contain a capital letter, a special character and be a minimum of 8 characters';
      } else if (error?.code == 'UsernameExistsException') {
        errorMsg = 'User already exists';
      }
      console.log('actionEmailSignUp[ERROR]', error);
      showAlert('Register Account Err!', errorMsg);
      return { status: false, message: errorMsg };
    }
  },
);

export const actionFetchUserProfile = createAsyncThunk(
  'user/fetch',
  async () => {
    const res = await api.GET('users/profile');
    if (res?.status === 200) return res?.data;
    return;
  },
);

const initialState = {
  registerSuccess: false,
  registerData: {},
  profile: null,
  token: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRegisterSuccess: (state, action) => {
      state.registerSuccess = action.payload;
    },
    setRegisterData: (state, action) => {
      state.registerData = action.payload;
    },
    setUserAuthToken: (state, action) => {
      const token = action.payload;
      state.token = token;
      api.defaultHeader({ Authorization: token ? 'Bearer ' + token : null });
    },
    updateProfileData: (state, action) => {
      state.profile = Object.assign(state.profile, action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(actionFetchUserProfile.fulfilled, (state, action) => {
      const profile = action.payload;
      if (profile) {
        state.profile = profile;
      }
    });
    builder.addCase(USER_SESSION_EXPIRED, (state) => {
      state = Object.assign(state, {
        ...initialState,
      });
    });
  },
});

export const {
  setRegisterSuccess,
  setRegisterData,
  setUserAuthToken,
  updateProfileData,
} = userSlice.actions;

export default userSlice.reducer;
