import React from 'react';
import './index.css';

function WidgetTabBar({ activeTab, setActiveTab }) {
  const _tabPressed = (route) => setActiveTab(route);

  return (
    <React.Fragment>
      <div className="planna-chat-boot-widget-container-tab-bar">
        <ul>
          <li>
            <a
              role="button"
              className={`${activeTab == 'anna' ? 'active' : ''}`}
              onClick={() => _tabPressed('anna')}
            >
              Anna
            </a>
            <div
              className={`indicator ${
                activeTab == 'anna' ? 'active-indicator' : ''
              }`}
            />
          </li>
          <li>
            <a
              role="button"
              className={`${activeTab == 'support' ? 'active' : ''}`}
              onClick={() => _tabPressed('support')}
            >
              Support
            </a>
            <div
              className={`indicator ${
                activeTab == 'support' ? 'active-indicator' : ''
              }`}
            />
          </li>
          <li>
            <a
              role="button"
              className={`${activeTab == 'contact' ? 'active' : ''}`}
              onClick={() => _tabPressed('contact')}
            >
              Contact
            </a>
            <div
              className={`indicator ${
                activeTab == 'contact' ? 'active-indicator' : ''
              }`}
            />
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default WidgetTabBar;
