import React, { useState } from 'react';
import { Container } from 'reactstrap';
import './index.css';
import { RegisterForm } from './registerForm';
import { RegisterSuccess } from './registerSuccess';
import { useSelector } from 'react-redux';

function SignUpModal() {
  const { registerSuccess } = useSelector(({ user }) => user);
  const [visible, setVisible] = useState(false);

  const _handleVisible = () => {
    setVisible((pre) => !pre);
  };

  return (
    <React.Fragment>
      <input
        id="planna-register-dialog"
        type="hidden"
        onClick={_handleVisible}
      />
      {visible ? (
        <aside className="register-dialog-root" onClick={_handleVisible}>
          <Container className="d-flex justify-content-end">
            <div
              className={`planna-register-dialog ${
                registerSuccess && 'planna-register-dialog-success'
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {registerSuccess ? <RegisterSuccess IsModal /> : <RegisterForm />}
            </div>
          </Container>
        </aside>
      ) : null}
    </React.Fragment>
  );
}

export default React.memo(SignUpModal, () => true);
