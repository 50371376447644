import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import checkCircleIc from './icons/checkCircleIc.svg';
import './index.css';
import { useDispatch } from 'react-redux';
import { setRegisterSuccess } from '../../redux/reducers/userSlice';

export function RegisterSuccess({ IsModal }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setRegisterSuccess(false));
    };
  }, []);

  const _closeModal = () => {
    dispatch(setRegisterSuccess(false));
    document.getElementById('planna-register-dialog')?.click();
  };

  return (
    <React.Fragment>
      <div className="planna-register-success-container d-grid text-center">
        <img src={checkCircleIc} />
        <h3>Success!</h3>
        <p>
          Your account has been successfully created.
          <br />
          Check your email to verify your account and get started.
        </p>
        {IsModal ? (
          <button type="button" onClick={_closeModal}>
            Close
          </button>
        ) : (
          <Link to="/">Back to Home</Link>
        )}
      </div>
    </React.Fragment>
  );
}
