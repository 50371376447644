import React, { useCallback, useEffect, useReducer, useState } from 'react';
import './index.css';
import chatIc from './chatIc.svg';
import closeIc from './closeIc.svg';
import WidgetTabBar from './WidgetTabBar';
import Contact from './Contact';
import AnnaWidget from './Anna';
import { SUPPORT_LIST_QUESTIONS } from './const';
import { api } from '../../utils/api';

function Widget(visibility = false) {
  const [visible, setVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('anna');

  const [widgetState, dispatch] = useReducer(widgetReducer, initialState);

  useEffect(() => {
    _loadSuggestions();
  }, []);

  const _loadSuggestions = async () => {
    const { status, data } = await api.GET('api/default-chat-prompts');
    if (status === 200) {
      dispatch({ type: 'annaSuggestionQuestions', payload: data });
    }
  };

  const _toggleVisible = useCallback(() => setVisible((pre) => !pre), []);

  return (
    <React.Fragment>
      {visible ? (
        <aside className="planna-chat-boot-widget-container">
          <WidgetTabBar activeTab={activeTab} setActiveTab={setActiveTab} />
          <div style={{ paddingTop: 60, height: '100%' }}>
            {activeTab == 'contact' && <Contact />}
            {activeTab == 'anna' && (
              <AnnaWidget type="anna" globalState={widgetState} />
            )}
            {activeTab == 'support' && (
              <AnnaWidget type="support" globalState={widgetState} />
            )}
          </div>
        </aside>
      ) : null}

      <aside
        role="button"
        className="planna-chat-boot-widget-button"
        onClick={_toggleVisible}
      >
        <img
          src={visible ? closeIc : chatIc}
          alt="Anna AI chat boot"
          className="img-fluid"
        />
      </aside>
    </React.Fragment>
  );
}
export default React.memo(Widget, () => true);

const initialState = {
  annaSuggestionQuestions: [],
  supportSuggestionQuestions: SUPPORT_LIST_QUESTIONS,
};

function widgetReducer(state = initialState, action) {
  switch (action?.type) {
    case 'annaSuggestionQuestions':
      state.annaSuggestionQuestions = action?.payload ?? [];
      return state;

    default:
      return state;
  }
}
