import React, { useEffect, useRef, useState } from 'react';

const AnimatedDots = (props) => {
  const [dots, setDots] = useState('');
  const _dots = useRef('');
  const _timer = useRef(null);
  useEffect(() => {
    if (_timer.current != null) {
      clearInterval(_timer.current);
      _timer.current = null;
    }
    _timer.current = setInterval(() => {
      if (_dots.current.length == 3) {
        _dots.current = '';
      } else {
        _dots.current = _dots.current + '.';
      }
      setDots(_dots.current);
    }, 500);

    return () => {
      clearInterval(_timer.current);
      _timer.current = null;
    };
  }, []);

  return <span {...props}>{dots}</span>;
};

export default React.memo(AnimatedDots, () => true);
